import React from 'react';

const LineChart = (props: {h: number, w?: number}) => {
    return (
        <svg width={props.w ? `${props.w}vw` : "3600"} height={props.h} viewBox="0 0 4096 284" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4096" y="284" width="4096" height="220" transform="rotate(-180 4096 284)" fill="#33AD51"/>
                <rect x="4083" y="284" width="5" height="220" transform="rotate(-180 4083 284)" fill="#D64249"/>
                <rect x="4026" y="284" width="6" height="220" transform="rotate(-180 4026 284)" fill="#D64249"/>
                <rect x="4032" y="284" width="2" height="220" transform="rotate(-180 4032 284)" fill="#D64249"/>
                <rect x="4005" y="284" width="36" height="220" transform="rotate(-180 4005 284)" fill="#D64249"/>
                <rect x="3954" y="284" width="27" height="220" transform="rotate(-180 3954 284)" fill="#D64249"/>
                <rect x="3885" y="284" width="7" height="220" transform="rotate(-180 3885 284)" fill="#D64249"/>
                <rect x="3810" y="284" width="56" height="220" transform="rotate(-180 3810 284)" fill="#D64249"/>
                <rect x="3714" y="284" width="49" height="220" transform="rotate(-180 3714 284)" fill="#D64249"/>
                <rect x="3538" y="284" width="5" height="220" transform="rotate(-180 3538 284)" fill="#D64249"/>
                <rect x="3475" y="284" width="3" height="220" transform="rotate(-180 3475 284)" fill="#D64249"/>
                <rect x="3435" y="284" width="3" height="220" transform="rotate(-180 3435 284)" fill="#D64249"/>
                <rect x="3435" y="284" width="3" height="220" transform="rotate(-180 3435 284)" fill="#D64249"/>
                <rect x="3196" y="284" width="10" height="220" transform="rotate(-180 3196 284)" fill="#D64249"/>
                <rect x="3166" y="284" width="6" height="220" transform="rotate(-180 3166 284)" fill="#D64249"/>
                <rect x="3153" y="284" width="6" height="220" transform="rotate(-180 3153 284)" fill="#D64249"/>
                <rect x="3127" y="284" width="6" height="220" transform="rotate(-180 3127 284)" fill="#D64249"/>
                <rect x="3127" y="284" width="6" height="220" transform="rotate(-180 3127 284)" fill="#D64249"/>
                <rect x="3042" y="284" width="21" height="220" transform="rotate(-180 3042 284)" fill="#D64249"/>
                <rect x="2875" y="284" width="21" height="220" transform="rotate(-180 2875 284)" fill="#D64249"/>
                <rect x="2812" y="284" width="9" height="220" transform="rotate(-180 2812 284)" fill="#D64249"/>
                <rect x="2797" y="284" width="7" height="220" transform="rotate(-180 2797 284)" fill="#D64249"/>
                <rect x="2731" y="284" width="4" height="220" transform="rotate(-180 2731 284)" fill="#D64249"/>
                <rect x="2662" y="284" width="36" height="220" transform="rotate(-180 2662 284)" fill="#D64249"/>
                <rect x="2541" y="284" width="18" height="220" transform="rotate(-180 2541 284)" fill="#D64249"/>
                <rect x="2559" y="284" width="2" height="220" transform="rotate(-180 2559 284)" fill="#D64249"/>
                <rect x="2555" y="284" width="2" height="220" transform="rotate(-180 2555 284)" fill="#D64249"/>
                <rect x="2456" y="284" width="11" height="220" transform="rotate(-180 2456 284)" fill="#D64249"/>
                <rect x="2433" y="284" width="4" height="220" transform="rotate(-180 2433 284)" fill="#D64249"/>
                <rect x="2247" y="284" width="4" height="220" transform="rotate(-180 2247 284)" fill="#D64249"/>
                <rect x="2182" y="284" width="4" height="220" transform="rotate(-180 2182 284)" fill="#D64249"/>
                <rect x="2149" y="284" width="10" height="220" transform="rotate(-180 2149 284)" fill="#D64249"/>
                <rect x="2099" y="284" width="13" height="220" transform="rotate(-180 2099 284)" fill="#D64249"/>
                <rect x="1981" y="284" width="10" height="220" transform="rotate(-180 1981 284)" fill="#D64249"/>
                <rect x="1862" y="284" width="3" height="220" transform="rotate(-180 1862 284)" fill="#D64249"/>
                <rect x="1722" y="284" width="6" height="220" transform="rotate(-180 1722 284)" fill="#D64249"/>
                <rect x="1676" y="284" width="70" height="220" transform="rotate(-180 1676 284)" fill="#D64249"/>
                <rect x="1598" y="284" width="89" height="220" transform="rotate(-180 1598 284)" fill="#D64249"/>
                <rect x="1462" y="284" width="7" height="220" transform="rotate(-180 1462 284)" fill="#D64249"/>
                <rect x="1260" y="284" width="38" height="220" transform="rotate(-180 1260 284)" fill="#D64249"/>
                <rect x="1141" y="284" width="14" height="220" transform="rotate(-180 1141 284)" fill="#D64249"/>
                <rect x="988" y="284" width="10" height="220" transform="rotate(-180 988 284)" fill="#D64249"/>
                <rect x="920" y="284" width="10" height="220" transform="rotate(-180 920 284)" fill="#D64249"/>
                <rect x="823" y="284" width="6" height="220" transform="rotate(-180 823 284)" fill="#D64249"/>
                <rect x="778" y="284" width="6" height="220" transform="rotate(-180 778 284)" fill="#D64249"/>
                <rect x="765" y="284" width="6" height="220" transform="rotate(-180 765 284)" fill="#D64249"/>
                <rect x="687" y="284" width="10" height="220" transform="rotate(-180 687 284)" fill="#D64249"/>
                <rect width="4" height="220" transform="matrix(1 8.74228e-08 8.74228e-08 -1 605 284)" fill="#D64249"/>
                <rect width="15" height="220" transform="matrix(1 8.74228e-08 8.74228e-08 -1 532 284)" fill="#D64249"/>
                <rect width="6" height="220" transform="matrix(1 8.74228e-08 8.74228e-08 -1 458 284)" fill="#D64249"/>
                <rect width="6" height="220" transform="matrix(1 8.74228e-08 8.74228e-08 -1 166 284)" fill="#D64249"/>
                <rect width="14" height="220" transform="matrix(1 8.74228e-08 8.74228e-08 -1 130 284)" fill="#D64249"/>
                <rect width="7" height="220" transform="matrix(1 8.74228e-08 8.74228e-08 -1 0 284)" fill="#D64249"/>
                <rect x="4018" y="284" width="13" height="220" transform="rotate(-180 4018 284)" fill="#8F338F"/>
                <rect x="3927" y="284" width="6" height="220" transform="rotate(-180 3927 284)" fill="#8F338F"/>
                <rect x="3915" y="284" width="6" height="220" transform="rotate(-180 3915 284)" fill="#8F338F"/>
                <rect x="4051" y="284" width="13" height="220" transform="rotate(-180 4051 284)" fill="#8F338F"/>
                <rect x="3516" y="284" width="3" height="220" transform="rotate(-180 3516 284)" fill="#8F338F"/>
                <rect x="3432" y="284" width="3" height="220" transform="rotate(-180 3432 284)" fill="#8F338F"/>
                <rect x="3160" y="284" width="7" height="220" transform="rotate(-180 3160 284)" fill="#8F338F"/>
                <rect x="2943" y="284" width="23" height="220" transform="rotate(-180 2943 284)" fill="#8F338F"/>
                <rect x="2898" y="284" width="6" height="220" transform="rotate(-180 2898 284)" fill="#8F338F"/>
                <rect x="2881" y="284" width="6" height="220" transform="rotate(-180 2881 284)" fill="#8F338F"/>
                <rect x="2881" y="284" width="6" height="220" transform="rotate(-180 2881 284)" fill="#8F338F"/>
                <rect x="2553" y="284" width="12" height="220" transform="rotate(-180 2553 284)" fill="#8F338F"/>
                <rect x="2557" y="284" width="2" height="220" transform="rotate(-180 2557 284)" fill="#8F338F"/>
                <rect x="2470" y="284" width="2" height="220" transform="rotate(-180 2470 284)" fill="#8F338F"/>
                <rect x="2466" y="284" width="2" height="220" transform="rotate(-180 2466 284)" fill="#8F338F"/>
                <rect x="2462" y="284" width="6" height="220" transform="rotate(-180 2462 284)" fill="#8F338F"/>
                <rect x="2389" y="284" width="6" height="220" transform="rotate(-180 2389 284)" fill="#8F338F"/>
                <rect x="2204" y="284" width="6" height="220" transform="rotate(-180 2204 284)" fill="#8F338F"/>
                <rect x="2060" y="284" width="3" height="220" transform="rotate(-180 2060 284)" fill="#8F338F"/>
                <rect x="2013" y="284" width="3" height="220" transform="rotate(-180 2013 284)" fill="#8F338F"/>
                <rect x="1955" y="284" width="1" height="220" transform="rotate(-180 1955 284)" fill="#8F338F"/>
                <rect x="1816" y="284" width="7" height="220" transform="rotate(-180 1816 284)" fill="#8F338F"/>
                <rect x="1816" y="284" width="7" height="220" transform="rotate(-180 1816 284)" fill="#8F338F"/>
                <rect x="1761" y="284" width="3" height="220" transform="rotate(-180 1761 284)" fill="#8F338F"/>
                <rect x="1683" y="284" width="7" height="220" transform="rotate(-180 1683 284)" fill="#8F338F"/>
                <rect x="1509" y="284" width="20" height="220" transform="rotate(-180 1509 284)" fill="#8F338F"/>
                <rect x="1484" y="284" width="22" height="220" transform="rotate(-180 1484 284)" fill="#8F338F"/>
                <rect x="1442" y="284" width="66" height="220" transform="rotate(-180 1442 284)" fill="#8F338F"/>
                <rect x="1359" y="284" width="3" height="220" transform="rotate(-180 1359 284)" fill="#8F338F"/>
                <rect x="1367" y="284" width="5" height="220" transform="rotate(-180 1367 284)" fill="#8F338F"/>
                <rect x="1331" y="284" width="8" height="220" transform="rotate(-180 1331 284)" fill="#8F338F"/>
                <rect x="1302" y="284" width="12" height="220" transform="rotate(-180 1302 284)" fill="#8F338F"/>
                <rect x="1264" y="284" width="4" height="220" transform="rotate(-180 1264 284)" fill="#8F338F"/>
                <rect x="1180" y="284" width="5" height="220" transform="rotate(-180 1180 284)" fill="#8F338F"/>
                <rect x="1127" y="284" width="26" height="220" transform="rotate(-180 1127 284)" fill="#8F338F"/>
                <rect x="1077" y="284" width="7" height="220" transform="rotate(-180 1077 284)" fill="#8F338F"/>
                <rect x="947" y="284" width="8" height="220" transform="rotate(-180 947 284)" fill="#8F338F"/>
                <rect x="786" y="284" width="8" height="220" transform="rotate(-180 786 284)" fill="#8F338F"/>
                <rect x="772" y="284" width="8" height="220" transform="rotate(-180 772 284)" fill="#8F338F"/>
                <rect x="709" y="284" width="22" height="220" transform="rotate(-180 709 284)" fill="#8F338F"/>
                <rect x="677" y="284" width="11" height="220" transform="rotate(-180 677 284)" fill="#8F338F"/>
                <rect x="605" y="284" width="1" height="220" transform="rotate(-180 605 284)" fill="#8F338F"/>
                <rect x="593" y="284" width="1" height="220" transform="rotate(-180 593 284)" fill="#8F338F"/>
                <rect x="381" y="284" width="2" height="220" transform="rotate(-180 381 284)" fill="#8F338F"/>
                <rect x="360" y="284" width="2" height="220" transform="rotate(-180 360 284)" fill="#8F338F"/>
                <rect x="351" y="284" width="4" height="220" transform="rotate(-180 351 284)" fill="#8F338F"/>
                <rect x="343" y="284" width="4" height="220" transform="rotate(-180 343 284)" fill="#8F338F"/>
                <rect x="343" y="284" width="4" height="220" transform="rotate(-180 343 284)" fill="#8F338F"/>
                <rect x="238" y="284" width="4" height="220" transform="rotate(-180 238 284)" fill="#8F338F"/>
                <rect x="225" y="284" width="2" height="220" transform="rotate(-180 225 284)" fill="#8F338F"/>
                <rect x="179" y="284" width="2" height="220" transform="rotate(-180 179 284)" fill="#8F338F"/>
                <rect x="772" y="284" width="8" height="220" transform="rotate(-180 772 284)" fill="#8F338F"/>
                <rect x="1372" y="284" width="2" height="220" transform="rotate(-180 1372 284)" fill="#8F338F"/>
                <rect x="1966" y="284" width="3" height="220" transform="rotate(-180 1966 284)" fill="#8F338F"/>
        </svg>

    );
};

export default LineChart;