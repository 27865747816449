import React, {useLayoutEffect, useRef} from 'react';
import './ChartOne.scss'
import Diagram from "../Diagram/Diagram";
import LineChart from "../../../assets/img/lineChart/lineChart";

const ChartOne = React.memo(() => {

    return (
        <div className='chart-one'>
            <div className='chart-one-wrapper'>
                <div className='chart-one-left-menu'>
                    <div className='chart-one-worker-name'>
                        Андрей Гуськов - электрик.
                        Электрики 08.06.2023
                    </div>
                    <div className='chart-one-diagram'>
                        <div>
                            <div>
                                Соотношение действий на 08.06.2023
                            </div>
                            <div className='diagram-one'>
                                <Diagram/>
                            </div>
                            <div className='diagram-one-description'>
                                <div className='diagram-one-legend'>
                                <span className='diagram-one-percent'
                                      style={{background: '#33AD51', width: '78'}}></span>
                                    <div>Работа 71.42%</div>
                                </div>
                                <div className='diagram-one-legend'>
                                <span className='diagram-one-percent'
                                      style={{background: '#D64249', width: '31px'}}></span>
                                    <div>Простой 24.13%</div>
                                </div>
                                <div className='diagram-one-legend'>
                                <span className='diagram-one-percent'
                                      style={{background: '#8F338F', width: '12px'}}></span>
                                    <div>Перемещения 4.45%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='chart-one-right-menu'>
                    <div className='chart-one-buttons'>
                        <div className='chart-one-button'>
                            Предыдущий рабочий
                        </div>
                        <div className='chart-one-button'>
                            Следующий рабочий
                        </div>

                        <div className='chart-one-button'>
                            Скачать отчет
                        </div>
                    </div>
                    <div className='chart-one-charts'>
                        <div>Деятельность в течение смены 08.06.2023</div>
                        <img className='work-day-chart' src='/images/workDayChart.smal.png'/>
                        <div className='work-day-chart-time'>
                            <div>8.00</div>
                            <div>9.00</div>
                            <div>10.00</div>
                            <div>11.00</div>
                            <div>12.00</div>
                            <div>13.00</div>
                            <div>14.00</div>
                            <div>15.00</div>
                            <div>16.00</div>
                            <div>17.00</div>
                            <div>18.00</div>
                        </div>
                        <div className='work-day-chart-big'>
                            <LineChart h={250}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ChartOne;
