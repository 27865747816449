import React from 'react';
import './ChartTwo.scss'
import {Bar, Pie} from "react-chartjs-2";


const ChartTwo = React.memo(() => {

    const data1 = {
        labels: [
            'Простой',
            'Перемещения',
            'Работа'
        ],
        datasets: [{
            data: [50.98, 18.36, 30.66],
            backgroundColor: [
                '#D64249',
                '#8F338F',
                '#33AD51',
            ],
            hoverOffset: 4,
        }],
    };
    const data2 = {
        labels: [
            'Простой',
            'Перемещения',
            'Работа'
        ],
        datasets: [{
            data: [40.11, 17.22, 42.67],
            backgroundColor: [
                '#D64249',
                '#8F338F',
                '#33AD51',
            ],
            hoverOffset: 4,
        }],
    };
    const data3 = {
        labels: [
            'Простой',
            'Перемещения',
            'Работа'
        ],
        datasets: [{
            data: [26.27, 3.86, 69.87],
            backgroundColor: [
                '#D64249',
                '#8F338F',
                '#33AD51',
            ],
            hoverOffset: 4,
        }],
    };

    const width = window.innerWidth < 980 ? '50' : '12'

    return (
        <div className='chart-one'>
            <div className='chart-one-table'>
                {window.innerWidth < 980 ? null :
                    <div className='chart-one-table-row'>
                        <div>
                            <div></div>
                            <div className='diagram-one-name'>Работа</div>
                            <div className='diagram-one-name'>Простой</div>
                            <div className='diagram-one-name'>Перемещения</div>
                        </div>

                        <div>
                            <div></div>

                            <div className='diagram-one-legend'>
                        <span className='diagram-one-percent'
                              style={{background: '#33AD51', width: '20px', borderRadius: '3px'}}></span>
                            </div>
                            <div className='diagram-one-legend'>
                        <span className='diagram-one-percent'
                              style={{background: '#D64249', width: '20px', borderRadius: '3px'}}></span>
                            </div>
                            <div className='diagram-one-legend'>
                        <span className='diagram-one-percent'
                              style={{background: '#8F338F', width: '20px', borderRadius: '3px'}}></span>
                            </div>
                        </div>
                    </div>}
                <div className='chart-one-table-col'>
                    <div>
                        {window.innerWidth < 980 ? <div className='chart-one-table-row'>
                                <div>
                                    <div style={{textAlign: 'center'}}>Cубподрядчик 1</div>

                                    <div className='diagram-one-legend'>
                                        <div className='diagram-one-name'>Работа</div>

                                        <span className='diagram-one-percent'
                                              style={{
                                                  background: '#33AD51',
                                                  minWidth: '20px',
                                                  borderRadius: '3px'
                                              }}></span>
                                        <div>30.66 %</div>
                                    </div>

                                    <div className='diagram-one-legend'>
                                        <div className='diagram-one-name'>Простой</div>

                                        <span className='diagram-one-percent'
                                              style={{
                                                  background: '#D64249',
                                                  minWidth: '20px',
                                                  borderRadius: '3px'
                                              }}></span>
                                        <div>50.98 %</div>

                                    </div>
                                    <div className='diagram-one-legend'>
                                        <div className='diagram-one-name'>Перемещения</div>

                                        <span className='diagram-one-percent'
                                              style={{
                                                  background: '#8F338F',
                                                  minWidth: '20px',
                                                  borderRadius: '3px'
                                              }}></span>
                                        <div>18.36 %</div>

                                    </div>
                                </div>
                            </div> :
                            <div className='chart-one-table-col-names'>
                                <div>Cубподрядчик 1</div>
                                <div>30.66 %</div>
                                <div>50.98 %</div>
                                <div>18.36 %</div>
                            </div>}
                    </div>


                    <div style={{
                        width: `${width}vw`,
                        marginTop: '50px',
                        background: 'rgba(0,0,0,0)',
                        position: 'relative',
                        cursor: "pointer"
                    }}>
                        <Pie data={data1}/>
                        <div style={{position: "absolute", top: '10%', left: '-30%'}}>30.66 %</div>
                        <div style={{position: "absolute", top: '45%', left: '105%', width: '100%'}}>50.98 %</div>
                        <div style={{position: "absolute", top: '92%', left: '0%'}}>18.36 %</div>
                    </div>
                </div>
                <div className='chart-one-table-col'>
                    <div>
                        {window.innerWidth < 980 ? <div className='chart-one-table-row'>
                            <div>
                                <div style={{textAlign: 'center'}}>Cубподрядчик 2</div>

                                <div className='diagram-one-legend'>
                                    <div className='diagram-one-name'>Работа</div>

                                    <span className='diagram-one-percent'
                                          style={{
                                              background: '#33AD51',
                                              minWidth: '20px',
                                              borderRadius: '3px'
                                          }}></span>
                                    <div>42.67 %</div>
                                </div>

                                <div className='diagram-one-legend'>
                                    <div className='diagram-one-name'>Простой</div>

                                    <span className='diagram-one-percent'
                                          style={{
                                              background: '#D64249',
                                              minWidth: '20px',
                                              borderRadius: '3px'
                                          }}></span>
                                    <div>40.11 %</div>

                                </div>
                                <div className='diagram-one-legend'>
                                    <div className='diagram-one-name'>Перемещения</div>

                                    <span className='diagram-one-percent'
                                          style={{
                                              background: '#8F338F',
                                              minWidth: '20px',
                                              borderRadius: '3px'
                                          }}></span>
                                    <div>17.22 %</div>

                                </div>
                            </div>
                        </div> : <div className='chart-one-table-col-names'>
                            <div>Cубподрядчик 2</div>
                            <div>42.67 %</div>
                            <div>40.11 %</div>
                            <div>17.22 %</div>
                        </div>}
                    </div>


                    <div style={{
                        width: `${width}vw`,
                        marginTop: '50px',
                        background: 'rgba(0,0,0,0)',
                        position: 'relative',
                        cursor: "pointer"
                    }}>
                        <Pie data={data2}/>
                        <div style={{position: "absolute", top: '10%', left: '-30%'}}>42.67 %</div>
                        <div style={{position: "absolute", top: '23%', left: '105%', width: '100%'}}>40.11 %</div>
                        <div style={{position: "absolute", top: '98%', left: '42%'}}>17.22 %</div>
                    </div>
                </div>
                <div className='chart-one-table-col'>
                    <div>
                        {window.innerWidth < 980 ? <div className='chart-one-table-row'>
                            <div>
                                <div style={{textAlign: 'center'}}>Cубподрядчик 3</div>

                                <div className='diagram-one-legend'>
                                    <div className='diagram-one-name'>Работа</div>

                                    <span className='diagram-one-percent'
                                          style={{
                                              background: '#33AD51',
                                              minWidth: '20px',
                                              borderRadius: '3px'
                                          }}></span>
                                    <div>69.87 %</div>
                                </div>

                                <div className='diagram-one-legend'>
                                    <div className='diagram-one-name'>Простой</div>

                                    <span className='diagram-one-percent'
                                          style={{
                                              background: '#D64249',
                                              minWidth: '20px',
                                              borderRadius: '3px'
                                          }}></span>
                                    <div>26.27 %</div>

                                </div>
                                <div className='diagram-one-legend'>
                                    <div className='diagram-one-name'>Перемещения</div>

                                    <span className='diagram-one-percent'
                                          style={{
                                              background: '#8F338F',
                                              minWidth: '20px',
                                              borderRadius: '3px'
                                          }}></span>
                                    <div>3.86 %</div>

                                </div>
                            </div>
                        </div> : <div className='chart-one-table-col-names'>
                            <div>Cубподрядчик 3</div>
                            <div>69.87 %</div>
                            <div>26.27 %</div>
                            <div>3.86 %</div>
                        </div>}
                    </div>

                    <div>
                    </div>
                    <div style={{
                        width: `${width}vw`,
                        marginTop: '50px',
                        background: 'rgba(0,0,0,0)',
                        position: 'relative',
                        cursor: "pointer"
                    }}>
                        <Pie data={data3}/>
                        {/*<div className='legend' style={{transform: 'translate(20px, -15px) rotate(127deg)'}}></div>*/}
                        <div style={{
                            position: "absolute",
                            top: '85%',
                            left: '-15%',
                            borderRadius: '30px'
                        }}>69.87 %
                        </div>
                        <div style={{
                            position: "absolute",
                            top: '5%',
                            left: '95%',
                            width: '100%',
                            borderRadius: '30px'
                        }}>26.27 %
                        </div>
                        <div style={{
                            position: "absolute",
                            top: '50%',
                            left: '105%',
                            width: '100%',
                            borderRadius: '30px'
                        }}>3.86 %
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ChartTwo;
