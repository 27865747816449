import React from 'react';

const PhoneIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.6955 16.967V19.967C22.6966 20.2455 22.6396 20.5211 22.528 20.7763C22.4164 21.0315 22.2528 21.2606 22.0476 21.4488C21.8423 21.6371 21.6001 21.7805 21.3362 21.8697C21.0724 21.9589 20.7929 21.992 20.5155 21.967C17.4383 21.6326 14.4825 20.5811 11.8855 18.897C9.46932 17.3616 7.42083 15.3131 5.88549 12.897C4.19547 10.2882 3.14373 7.31797 2.81549 4.22697C2.7905 3.95044 2.82336 3.67173 2.91199 3.4086C3.00062 3.14546 3.14306 2.90366 3.33026 2.69859C3.51745 2.49352 3.74529 2.32968 3.99928 2.21749C4.25327 2.10531 4.52783 2.04723 4.80549 2.04697H7.80549C8.2908 2.0422 8.76128 2.21405 9.12925 2.5305C9.49722 2.84696 9.73757 3.28642 9.80549 3.76697C9.93211 4.72704 10.1669 5.6697 10.5055 6.57697C10.64 6.9349 10.6692 7.32389 10.5894 7.69785C10.5096 8.07182 10.3244 8.41508 10.0555 8.68697L8.78549 9.95697C10.209 12.4605 12.2819 14.5334 14.7855 15.957L16.0555 14.687C16.3274 14.4181 16.6706 14.2328 17.0446 14.1531C17.4186 14.0733 17.8076 14.1024 18.1655 14.237C19.0728 14.5755 20.0154 14.8103 20.9755 14.937C21.4613 15.0055 21.9049 15.2502 22.222 15.6245C22.5392 15.9988 22.7077 16.4765 22.6955 16.967Z"
                stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default PhoneIcon;