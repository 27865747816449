import React, {useContext, useLayoutEffect, useRef} from 'react';
import './Main.scss'
import Header from "../Header/Header";
import {TranslateContext} from '../../App';


const Main = () => {
    const translate = useContext(TranslateContext);
    const main = useRef<HTMLDivElement>(null)
    const text = useRef<HTMLDivElement>(null)
    const mainImg = useRef<HTMLImageElement>(null)
    useLayoutEffect(() => {
        let elShoe = document.querySelector('.about');
        // @ts-ignore
        // mainObserver.observe(elShoe);
    })

    const mainObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (main.current && entry.intersectionRatio) {
                // main.current.style.opacity = `${1 - entry.intersectionRatio}`
                // console.log(entry.boundingClientRect.y)
                // if (entry.boundingClientRect.y === 0) {
                //     // @ts-ignore
                //     document.querySelector('.about').classList.add('fixed')
                //     // entry.target.classList.add('fixed')
                // }
                // if (entry.boundingClientRect.y < 0) {
                //     elRef.current.style.transform = `translate3d(0,0,0)`
                //     about.current.style.position = `sticky`
                // }
                // if (entry.boundingClientRect.y < -heightForRemove) {
                //     elRef.current.style.transform = `translate3d(0,-200vh,0)`
                // }
            }
        });
    });

    const scrollToBlock = (value: number) => {
        // setScrollHeight(value)
        // setScrollTo(value)
    }

    const changeMedia = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        if (mainImg.current) {
            mainImg.current.style.opacity = '0';

        }
        e.currentTarget.style.opacity = '1'
    }


    return (
        <div className={'main'} ref={main}>

            <div className='main-header'>
                <Header/>
            </div>

            <div className='video'>
                <h1 ref={text} className='tagline'>{translate.main.slogan}</h1>

                <video onLoadedData={e => changeMedia(e)} autoPlay muted loop id="mainVideo">
                    <source src="/images/build.mp4" type="video/mp4"/>
                </video>
                <img ref={mainImg} src='/images/main-img.png' className='main-img'
                     onLoad={(e) => e.currentTarget.style.opacity = '1'}/>
            </div>
        </div>

    );
};

export default Main;