import React, {useContext, useLayoutEffect, useRef} from 'react';
import './Contacts.scss'
import Heading from "../atoms/Heading/Heading";
import ContactCard from "../atoms/ContactCard/ContactCard";
import {TranslateContext} from "../../App";


const Contacts = (props: { animation: boolean }) => {
        const translate = useContext(TranslateContext);

        const managers = [
            {
                name: translate.contacts.card1.name,
                country: translate.contacts.card1.country,
                lang: translate.contacts.card1.lang,
                avatar: 'images/avatar/mark.png',
                contact: {
                    phone: translate.contacts.card1.phone, mail: translate.contacts.card1.email, contactNumber: translate.contacts.card1.contactNumber
                }
            },
            {
                name: translate.contacts.card2.name,
                country: translate.contacts.card2.country,
                lang: translate.contacts.card2.lang,
                avatar: '',
                contact: {
                    phone: translate.contacts.card2.phone, mail: translate.contacts.card2.email, contactNumber: translate.contacts.card2.contactNumber
                }
            },
            {
                name: translate.contacts.card3.name,
                country: translate.contacts.card3.country,
                lang: translate.contacts.card3.lang,
                avatar: 'images/avatar/pavel.png',
                contact: {
                    phone: translate.contacts.card3.phone, mail: translate.contacts.card3.email, contactNumber: translate.contacts.card3.contactNumber
                }
            },
            {
                name: translate.contacts.card4.name,
                country: translate.contacts.card4.country,
                lang: translate.contacts.card4.lang,
                avatar: '',
                contact: {
                    phone: translate.contacts.card4.phone, mail: translate.contacts.card4.email, contactNumber: translate.contacts.card4.contactNumber
                }
            },

        ]


        const cards = useRef<HTMLDivElement>(null);
        const heading = useRef<HTMLDivElement>(null);

        let observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (cards.current && heading.current) {
                    if (entry.intersectionRatio > 0.1) {
                        cards.current.style.transform = `translate3d(0,0,0)`
                        heading.current.style.opacity = '1'
                    }

                    if (entry.intersectionRatio < 0.9) {
                        cards.current.style.transform = `translate3d(100vw,0,0)`
                        heading.current.style.opacity = '0'
                    }
                }
            });
        });


        useLayoutEffect(() => {
            if (props.animation) {
                let elShoe = document.querySelector('.contacts');
                // @ts-ignore
                observer.observe(elShoe);
            }
        })


        return (
            <div className='contacts' id='contacts'>
                <div className='contacts-wrapper'>
                    <div className={props.animation ? 'contacts-heading' : 'contacts-heading non-animation'}  ref={heading}>
                        <Heading text={translate.contacts.heading} color={'white'}/>
                    </div>
                    <div className={props.animation ? 'contacts-cards' : 'contacts-cards non-animation'}  ref={cards}>
                        <ContactCard {...managers[3]}/>
                    </div>
                </div>
            </div>

        );
    }
;

export default Contacts;
