import React from 'react';
import './Diagram.scss'
import { Pie} from "react-chartjs-2";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    registerables
} from "chart.js";
import {Chart, ArcElement} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,

);
ChartJS.register(ArcElement);
Chart.register(...registerables)

Chart.defaults.plugins.legend.display = false;
const Diagram = () => {


    const data = {
        labels: [
            'Простой',
            'Перемещения',
            'Работа'
        ],
        datasets: [{
            data: [24.13, 4.45, 71.42],
            backgroundColor: [
                '#D64249',
                '#8F338F',
                '#33AD51',
            ],
            hoverOffset: 4,
        }],
    };


    return (
        <div className='pie-chart-container'>
            {/*<div className="pie-chart"></div>*/}
            <div className='chart-diagram'>
                <Pie data={data} />
            </div>
        </div>
    );
};

export default Diagram;
