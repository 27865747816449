import React, {useContext, useLayoutEffect, useRef} from 'react';
import './SolveProblem.scss'
import Heading from "../atoms/Heading/Heading";
import {TranslateContext} from "../../App";

const SolveProblem = (props: {animation: boolean}) => {
    const translate = useContext(TranslateContext);

    const solve = useRef<HTMLDivElement>(null)
    const heading = useRef<HTMLDivElement>(null)
    const img1 = useRef<HTMLDivElement>(null)
    const img2 = useRef<HTMLDivElement>(null)
    const img3 = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (props.animation){
            let elShoe = document.querySelector('.solve-wrapper');
            // @ts-ignore
            mainObserver.observe(elShoe);
        }
    })

    const mainObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (solve.current && heading.current && img1.current && img2.current && img3.current) {
                // to down open
                if (entry.intersectionRatio > 0.2 && entry.boundingClientRect.y > 0) {
                    heading.current.style.opacity = '1'
                }

                if (entry.intersectionRatio > 0.6) {
                    img1.current.style.opacity = '1'
                    img2.current.style.opacity = '1'
                    img3.current.style.opacity = '1'
                    img1.current.style.transform = 'translate3d(0,0,0)'
                    img2.current.style.transform = 'translate3d(0,0,0)'
                    img3.current.style.transform = 'translate3d(0,0,0)'
                }
                if (entry.intersectionRatio < 0.9 && entry.boundingClientRect.y < 0) {
                        img1.current.style.opacity = '0'
                        img2.current.style.opacity = '0'
                        img3.current.style.opacity = '0'
                }
            }
        });
    });


    return (
        <div className={props.animation ? 'solve' : 'solve non-animation'} ref={solve}>
            <div className='solve-wrapper'>
                <div className={props.animation ? 'solve-heading' : 'solve-heading non-animation'}  ref={heading}>
                    <Heading text={translate.solveProblem.heading}/>
                </div>
                <div className='problems'>
                    <div className={props.animation ? 'problems-img' : 'problems-img non-animation'}  ref={img1}>
                        <img src='/images/Problem_1.png'/>
                        <p>{translate.solveProblem.description1}</p>
                    </div>
                    <div className={props.animation ? 'problems-img' : 'problems-img non-animation'} ref={img2}>
                        <img src='/images/Problem_2.png'/>
                        <p>{translate.solveProblem.description2}</p>
                    </div>
                    <div className={props.animation ? 'problems-img' : 'problems-img non-animation'}  ref={img3}>
                        <img src='/images/Problem_3.png'/>
                        <p>{translate.solveProblem.description3}</p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default SolveProblem;