import React, {useContext, useLayoutEffect, useRef} from 'react';
import Heading from '../atoms/Heading/Heading';
import './OtherSystems.scss'
import {TranslateContext} from "../../App";

const OtherSystems = (props: {animation: boolean}) => {
    const translate = useContext(TranslateContext);


    const description = useRef<HTMLDivElement>(null);
    const heading = useRef<HTMLDivElement>(null);

    const otherOne = useRef<HTMLDivElement>(null);
    const otherTwo = useRef<HTMLDivElement>(null);
    const otherThree = useRef<HTMLDivElement>(null);

    const imgOne = useRef<HTMLDivElement>(null);
    const imgTwo = useRef<HTMLDivElement>(null);
    const imgThree = useRef<HTMLDivElement>(null);


    let observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (description.current &&  heading.current && otherOne.current && otherTwo.current
                && otherThree.current && imgOne.current &&
                imgTwo.current && imgThree.current) {
                if (entry.intersectionRatio > 0.3) {
                    description.current.style.transform = `translate3d(0,0,0)`
                    heading.current.style.transform = `translate3d(0,0,0)`
                    otherOne.current.style.opacity = '1'
                    otherTwo.current.style.opacity = '1'
                    otherThree.current.style.opacity = '1'
                    otherOne.current.style.width = '450px'
                    otherTwo.current.style.width = '450px'
                    otherThree.current.style.width = '450px'
                    imgOne.current.style.opacity = '1'
                    imgTwo.current.style.opacity = '1'
                    imgThree.current.style.opacity = '1'
                }

                if (entry.intersectionRatio < 0.7) {
                    description.current.style.transform = `translate3d(100vw,0,0)`
                    heading.current.style.transform = `translate3d(-100vw,0,0)`
                    otherOne.current.style.width = '0'
                    otherTwo.current.style.width = '0'
                    otherThree.current.style.width = '0'
                    otherOne.current.style.opacity = '0'
                    otherTwo.current.style.opacity = '0'
                    otherThree.current.style.opacity = '0'
                    imgOne.current.style.opacity = '0'
                    imgTwo.current.style.opacity = '0'
                    imgThree.current.style.opacity = '0'
                }
            }
        });
    });


    useLayoutEffect(() => {
        if (props.animation){
            let elShoe = document.querySelector('.other');
            // @ts-ignore
            observer.observe(elShoe);
        }
    })


    return (
        <div className='other'>
            <div className='other-wrapper'>
                <div className='other-header'>
                    <div ref={heading} className={props.animation ? 'other-heading' : 'other-heading non-animation'} >
                        <Heading color={'red'} text={translate.otherSystems.heading}/>
                    </div>

                </div>
                <div className={'other-info'}>
                    <div className='other-systems'>
                        <div className='other-system'>
                            <div className={props.animation ? 'other-system-img' : 'other-system-img non-animation'}  ref={imgOne}>
                                <img alt='' src='/images/other-systems/1.png'/>
                            </div>
                            <div className={props.animation ? 'other-system-description' : 'other-system-description non-animation'}  ref={otherOne}>
                                <p className='other-system-name'>{translate.otherSystems.system1.title}</p>
                                <p>{translate.otherSystems.system1.description}</p>
                            </div>
                        </div>
                        <div className='other-system'>
                            <div className={props.animation ? 'other-system-img' : 'other-system-img non-animation'}  ref={imgTwo}>
                                <img alt='' src='/images/other-systems/2.png' style={{width: '100px', height: '100px'}}/>
                            </div>
                            <div  className={props.animation ? 'other-system-description' : 'other-system-description non-animation'}   ref={otherTwo}>
                                <p className='other-system-name'>{translate.otherSystems.system2.title}</p>
                                <p>{translate.otherSystems.system2.description}</p>
                            </div>
                        </div>
                        <div className='other-system'>
                            <div className={props.animation ? 'other-system-img' : 'other-system-img non-animation'}  ref={imgThree}>
                                <img alt='' src='/images/other-systems/3.png'/>
                            </div>
                            <div className={props.animation ? 'other-system-description' : 'other-system-description non-animation'}   ref={otherThree}>
                                <p className='other-system-name'>{translate.otherSystems.system3.title}</p>
                                <p>{translate.otherSystems.system3.description}</p>
                            </div>
                        </div>
                    </div>
                    <p className={props.animation ? 'other-description' : 'other-description non-animation'}  ref={description}>
                        {translate.otherSystems.description}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OtherSystems;