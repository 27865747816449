import React, {useContext, useEffect, useLayoutEffect, useRef} from 'react';
import './HowItsWorkSctatic.scss'
import {TranslateContext} from "../../App";

const HowItsWorkStatic = (props: { animation: boolean, scroll: number }) => {
    const translate = useContext(TranslateContext);

    return (
        <div className='how-work-static' onWheel={(e)=>console.log(e)}>
            <div className='how-work-wrapper-static'>
                <h2
                    className={'how-work-header-static'}>{translate.howItsWork.heading}</h2>
                <div className={'steps-wrapper-static'}>
                    <div className='steps-static'>
                        <div className={'full-static'}>
                            <img src='/images/step1.png'/>
                            <p>{translate.howItsWork.description1}</p>
                        </div>
                        <div className={'step-static'}><img
                            src='/images/step2.png'/>
                            <p>{translate.howItsWork.description2}</p></div>
                        <div className={'step-static last-step-static'}><img
                            src='/images/step3.png'/><p>{translate.howItsWork.description3}</p></div>
                    </div>
                    {window.innerWidth > 980 ?
                        <div className={'arrow-static'}>
                            <div className='line-static'></div>
                            <div className={'line-arrow-static'}></div>
                        </div>
                        : null}
                </div>

            </div>
        </div>

    );
};

export default HowItsWorkStatic;
