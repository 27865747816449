import React from 'react';
import './Partners.scss'

const Partners = (props: {animation: boolean}) => {

        return (
        <header className='partners'>
            <div className='partners-line'>
                {/*<img src='/images/logo-partners/ncc.png'/>*/}
                {/*<img style={{height: '55px'}} src='/images/logo-partners/navigine.png'/>*/}
                <img src='/images/logo-partners/samsung.png'/>
                <img style={{height: '70px'}} src='/images/logo-partners/pwc.png'/>
                <img src='/images/logo-partners/softline.png'/>
                <img src='/images/logo-partners/yandex.png'/>
                {/*<img  style={{height: '50px'}} src='/images/logo-partners/lanit.png'/>*/}
            </div>
        </header>
    );
};

export default Partners;