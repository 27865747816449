import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import './Sphere.scss'
import Heading from "../atoms/Heading/Heading";
import {TranslateContext} from "../../App";


const Sphere =  React.memo((props: { animation: boolean }) => {
    const translate = useContext(TranslateContext);


    const spheres = [{
        name: translate.sphere.sphere1.title,
        description: translate.sphere.sphere1.description,
        img: '/images/build.png'
    }, {
        name: translate.sphere.sphere3.title,
        description: translate.sphere.sphere3.description,
        img: '/images/energy.png'
    }, {
        name: translate.sphere.sphere2.title,
        description: translate.sphere.sphere2.description,
        img:'/images/oil.png'

    }, {
        name: translate.sphere.sphere4.title,
        description: translate.sphere.sphere4.description,
        img: '/images/retail.png'
    }]

    const [sphereState, setSphere] = useState(0);
    // const sphereRef = useRef<HTMLObjectElement>(null);
    const heading = useRef<HTMLObjectElement>(null);
    const buttons = useRef<HTMLObjectElement>(null);
    const description = useRef<HTMLObjectElement>(null);
    const img = useRef<HTMLObjectElement>(null);

    useEffect(() => {
        // console.log(props)
        // @ts-ignore
        //  console.log(heading.current.offsetHeight )

    })

    useLayoutEffect(() => {
        if (props.animation) {
            let elShoe = document.querySelector('.spheres-wrapper');
            if (elShoe) {
                observer.observe(elShoe);
            }
        }
    })

    let observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            // console.log(entry.intersectionRatio, entry.boundingClientRect.y );
            if (heading.current && buttons.current && description.current && img.current) {
                // to down open
                if (entry.intersectionRatio > 0.2 && entry.boundingClientRect.y > 0) {
                    heading.current.style.transform = `translate3d(0,0,0)`
                    buttons.current.style.transform = `translate3d(0,0,0)`
                    img.current.style.transform = `translate3d(0,0,0)`
                    description.current.style.transform = `translate3d(0,0,0)`
                }


                // to down close
                if (entry.intersectionRatio < 0.9) {
                    heading.current.style.transform = `translate3d(-100vw,0,0)`
                    buttons.current.style.transform = `translate3d(100vw,0,0)`
                    img.current.style.transform = `translate3d(100vw,0,0)`
                    description.current.style.transform = `translate3d(-100vw,0,0)`
                }

                // to top

                // // console.log(entry.boundingClientRect.y)
                // if (entry.boundingClientRect.y < entry.boundingClientRect.height / 3) {
                //     elRef.current.style.transform = `translate3d(0,0,0)`
                //     about.current.style.position = `sticky`
                // }
                // if (entry.boundingClientRect.y < -heightForRemove) {
                //     elRef.current.style.transform = `translate3d(0,-200vh,0)`
                // }
            }
        });
    });


    return (
        <div className='spheres'>
            <div className='spheres-wrapper'>
                <div className='spheres-header'>
                    <div ref={heading} className={props.animation ? 'heading-ref' : 'heading-ref non-animation'}>
                        <Heading text={translate.sphere.heading} color={'white'}/>
                    </div>
                    <div ref={buttons}
                         className={props.animation ? 'spheres-buttons' : 'spheres-buttons non-animation'}>{spheres.map((el, i) =>
                        <button key={Math.random()}
                                className={i === sphereState ? ' button active' : 'button'}
                                onClick={() => setSphere(i)}><p>{el.name}</p></button>)}</div>
                </div>
                <div className='info-wrapper'>
                    <p className={props.animation ? 'spheres-description' : 'spheres-description non-animation'}
                       ref={description}>{spheres[sphereState].description}</p>
                    <div className={props.animation ? 'img' : 'img non-animation'} ref={img}>
                        <img src={spheres[sphereState].img}/>
                    </div>
                </div>
            </div>
        </div>

    );
});

export default Sphere;