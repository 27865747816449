import React, { useContext } from 'react';
import { TranslateContext } from '../../App';

const LogoIcon = () => {
    const translate = useContext(TranslateContext);

    return (
        <div style={{margin: '-200px 0 0 0'}}>
            <svg width="80vw" height="70vh" viewBox="0 0 1349 706" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M58.0184 657.729L475.519 347.728L890.519 347.728L1309.43 34.0413M1309.43 34.0413L1137.5 162.825M1309.43 34.0413L1311 22.5L1293.33 152.742M1309.43 34.0413L1322 36L1191.79 16.4045" stroke="#00ABBD" stroke-width="25"/>
                    <path d="M103.949 623.331L102.977 621.958C88.6186 601.672 60.5343 596.867 40.2487 611.226C19.9632 625.584 15.1582 653.668 29.5165 673.954L30.4882 675.327C44.8465 695.612 72.9308 700.417 93.2163 686.059C113.502 671.701 118.307 643.616 103.949 623.331Z" fill="#00ABBD"/>
                    <path d="M508.817 327.727C522.504 347.065 517.924 373.838 498.586 387.526C479.247 401.213 452.475 396.633 438.787 377.295C425.099 357.957 429.68 331.184 449.018 317.496C468.356 303.809 495.129 308.389 508.817 327.727Z" fill="#E6FDFF" stroke="#00ABBD" stroke-width="5"/>
                    <circle cx="892.438" cy="344.147" r="42.6374" transform="rotate(-35.2912 892.438 344.147)" fill="#E6FDFF" stroke="#00ABBD" stroke-width="5"/>
                    <path d="M51.1026 670.228L51.1026 663.124L61.0226 663.124L61.0226 637.908C61.0226 636.372 61.0866 634.836 61.0866 634.836L60.9586 634.836C60.9586 634.836 60.3826 635.924 58.9106 637.396L55.2626 640.852L50.3986 635.732L61.7906 624.788L69.0226 624.788L69.0226 663.124L78.8786 663.124L78.8786 670.228L51.1026 670.228Z" fill="white"/>
                    <path d="M458.231 372.228C457.975 370.756 457.847 369.476 457.847 368.196C457.847 351.812 479.095 349.188 479.095 340.164C479.095 336.196 476.023 333.828 472.183 333.828C468.727 333.828 465.655 336.26 463.991 339.204L457.847 335.108C460.471 330.244 465.591 326.02 472.887 326.02C480.823 326.02 487.671 330.884 487.671 339.46C487.671 354.052 467.319 356.548 466.935 365.124L488.375 365.124L488.375 372.228L458.231 372.228Z" fill="#05ACBC"/>
                    <path d="M875.951 358.916L880.175 352.58C882.607 355.076 886.511 357.188 890.543 357.188C895.215 357.188 898.671 354.308 898.671 350.404C898.671 345.604 894.319 343.364 888.943 343.364L885.807 343.364L884.015 339.14L892.335 329.284C894.063 327.172 895.599 325.828 895.599 325.828L895.599 325.7C895.599 325.7 894.191 325.892 891.567 325.892L877.999 325.892L877.999 318.788L905.711 318.788L905.711 323.972L894.639 336.836C900.911 337.732 906.991 342.212 906.991 350.084C906.991 357.828 901.167 364.996 890.991 364.996C885.615 364.996 879.919 362.948 875.951 358.916Z" fill="#05ACBC"/>

                <text x="130" y="645" fill="black" fontSize='1.5vw'>{translate.realization.step1_1}</text>
                <text x="130" y="680" fill="black" fontSize='1.5vw'>{translate.realization.step1_2}</text>
                <text x="30%" y="295" fill="black" fontSize='1.5vw' textAnchor='end'>{translate.realization.step2_1}</text>
                <text x="30%" y="330" fill="black" fontSize='1.5vw' textAnchor='end'>{translate.realization.step2_2}</text>
                <text x="30%" y="365" fill="black" fontSize='1.5vw' textAnchor='end'>{translate.realization.step2_3}</text>
                <text x="950" y="350" fill="black" fontSize='1.5vw'>{translate.realization.step3_1}</text>
                <text x="950" y="385" fill="black" fontSize='1.5vw'>{translate.realization.step3_2}</text>
                <text x="950" y="420" fill="black" fontSize='1.5vw'>{translate.realization.step3_3}</text>

                <defs>
                    <clipPath id="clip0_786_425">
                        <rect width="1349" height="706" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>

    );

};

export default LogoIcon;
