import React, {useLayoutEffect, useRef} from 'react';
import './Heading.scss'

const Heading = (props: { text: string, color?: string, position?: string }) => {

    return (
        <div className={`heading + ${props.position} ${props.color}`}>
            <h2>{props.text}</h2>
        </div>
    );
};

export default Heading;