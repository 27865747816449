import React, {useContext, useLayoutEffect, useRef, useState} from 'react';
import './Realization.scss'
import Heading from "../atoms/Heading/Heading";
import {TranslateContext} from "../../App";
import RealizationArrow from "../../assets/arrow/RealizationArrow";

const Realization = (props: { animation: boolean }) => {
    const translate = useContext(TranslateContext);

    const [mobile, setMobile] = useState(false)

    const startStep = useRef<HTMLDivElement>(null);
    const twoStep = useRef<HTMLDivElement>(null);
    const threeStep = useRef<HTMLDivElement>(null);
    const arrow = useRef<HTMLDivElement>(null);
    const all = useRef<HTMLDivElement>(null);
    const lineArrow = useRef<HTMLDivElement>(null);
    const heading = useRef<HTMLDivElement>(null);

    let observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            const screenPercent = entry.intersectionRatio
            const value = ((entry.intersectionRatio - 0.8) * 100) * 5
            if (heading.current && startStep.current && arrow.current && all.current
                && lineArrow.current && twoStep.current && threeStep.current) {
                if (screenPercent > 0.8 && entry.boundingClientRect.y > 0) {
                    startStep.current.style.opacity = '1.0';
                    arrow.current.style.width = `${value}vw`;
                }
                if (value > 20) {
                    heading.current.style.opacity = '1'
                    lineArrow.current.style.opacity = `1`;
                }
                if (value > 50) {
                    twoStep.current.style.opacity = '1';
                }
                if (value > 80) {
                    threeStep.current.style.opacity = '1';
                }
                if (screenPercent < 0.9 && entry.boundingClientRect.y < 0) {
                    heading.current.style.opacity = '0'
                }

                if (screenPercent < 0.8 && entry.boundingClientRect.y < 0) {

                    all.current.style.transform = `translate3d(200vw,0,0)`

                    //     startStep.current.style.opacity = '1.0';
                    //     console.log((entry.intersectionRatio))
                    //     arrow.current.style.width = `${((entry.intersectionRatio-0.8)*100)*5}vw`;
                }
                if (screenPercent > 0.8 && entry.boundingClientRect.y < 0) {
                    all.current.style.transform = `translate3d(0,0,0)`
                    //     startStep.current.style.opacity = '1.0';
                    //     console.log((entry.intersectionRatio))
                    //     arrow.current.style.width = `${((entry.intersectionRatio-0.8)*100)*5}vw`;
                }
            }

            // const heightForRemove = entry.boundingClientRect.height / 6
            // if (elRef.current) {
            //     if (entry.boundingClientRect.y < 0) {
            //         elRef.current.style.transform = `translate3d(0,0,0)`
            //     }
            //     if (entry.boundingClientRect.y < -heightForRemove) {
            //         elRef.current.style.transform = `translate3d(-1000px,0,0)`
            //     }
            // }
        });
    });


    useLayoutEffect(() => {
        let elShoe = document.querySelector('.how-work');
        if (elShoe) {
            observer.observe(elShoe);
        }
        const widthWindow = window.innerWidth
        if (widthWindow < 960) {
            setMobile(true)
        }

    })

    return (
        <div className='realization'>
            <div className='realization-wrapper'>
                <div className='realization-header'>
                    <div className='realization-heading'>
                        <Heading text={translate.realization.heading}/>
                        <p className='realization-description'>
                            {translate.realization.description}
                        </p>
                    </div>
                </div>
                {mobile ? <div className='realization-content'>
                        <div className='stages-arrow'>
                            <div className='stages-line'></div>
                            <div className='stages-line-arrow'></div>
                        </div>
                        <div className='stages'>
                            <div className='stage'>
                                <div className='stage-number'>3</div>
                                <div>
                                    <p>
                                        {translate.realization.step3_1} {translate.realization.step3_2} {translate.realization.step3_3}
                                    </p>
                                </div>

                            </div>
                            <div className='stage'>
                                <div className='stage-number'>2</div>
                                <div>
                                    <p>
                                        {translate.realization.step2_1} {translate.realization.step2_2} {translate.realization.step2_3}
                                    </p>

                                </div>

                            </div>
                            <div className='stage'>
                                <div className='stage-number fill'>1</div>
                                <div>
                                    <p>
                                        {translate.realization.step1_1} {translate.realization.step1_2}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> : <div className='realization-content'><RealizationArrow/></div>}
            </div>
        </div>
    );
};

export default Realization;
