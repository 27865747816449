import React, {useState} from 'react';
import './ChartThree.scss'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {chartData1, chartData2, chartData3} from "../../../assets/dataCharts";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

// ChartJS.defaults.scale.display = false;


const ChartThree = React.memo(() => {

    const labels = chartData1.map(el => {
        const day = el.name.substring(8, 10).toString()
        const month = el.name.substring(5, 7).toString()
        return `${day}.${month}`
    });

    const [chart1, setChart1] = useState(chartData1)
    const [chart2, setChart2] = useState(chartData2)
    const [chart3, setChart3] = useState(chartData3)
    const [legend, setLegend] = useState(labels)

    const changePeriod = (period: number) => {
        if (period === 1) {
            setChart1(chartData1.slice(-6))
            setChart2(chartData2.slice(-6))
            setChart3(chartData3.slice(-6))
            setLegend(labels.slice(-6))
        }
        if (period === 2) {
            setChart1(chartData1.slice(-30))
            setChart2(chartData2.slice(-30))
            setChart3(chartData3.slice(-30))
            setLegend(labels.slice(-30))
        }
        if (period === 3) {
            setChart1(chartData1.slice(-90))
            setChart2(chartData2.slice(-90))
            setChart3(chartData3.slice(-90))
            setLegend(labels.slice(-90))
        }
        if (period === 4) {
            setChart1(chartData1)
            setChart2(chartData2)
            setChart3(chartData3)
            setLegend(labels)
        }

    }



    const data = {
        legend,
        datasets: [
            {
                label: 'Набор данных 1',
                data: chart1.map(el => el.value),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                pointRadius: 0,
                tension: 0.1,
            }, {
                label: 'Набор данных 1',
                data: chart2.map(el => el.value),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                pointRadius: 0,
                tension: 0.1,
            },
            {
                label: 'Набор данных 1',
                data: chart3.map(el => el.value),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                pointRadius: 0,
                tension: 0.1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                position: 'bottom' as const,
                boxWidth: 20,
            },
            title: {
                display: false,
                text: 'Линейный график Chart.js',
            },
        },

    };

    return (
        <div className='chart-one'>
            <div>
                <button className='chart-three-button' onClick={() => changePeriod(1)}>Неделя</button>
                <button className='chart-three-button' onClick={() => changePeriod(2)}>Месяц</button>
                <button className='chart-three-button' onClick={() => changePeriod(3)}>Квартал</button>
                <button className='chart-three-button' onClick={() => changePeriod(4)}>Год</button>
            </div>
            <div className='chart-three'>
                <Line options={options} data={data} style={{maxWidth: '80vw', maxHeight: '60vh'}}/>
            </div>
        </div>
    );
});

export default ChartThree;
