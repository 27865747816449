export const chartData1 = [
    {
        "name": "2022-12-01",
        "value": "62.50"
    },
    {
        "name": "2022-11-30",
        "value": "58.86"
    },
    {
        "name": "2022-11-29",
        "value": "58.70"
    },
    {
        "name": "2022-11-28",
        "value": "66.50"
    },
    {
        "name": "2022-11-26",
        "value": "56.55"
    },
    {
        "name": "2022-11-25",
        "value": "57.01"
    },
    {
        "name": "2022-11-24",
        "value": "60.89"
    },
    {
        "name": "2022-11-23",
        "value": "58.41"
    },
    {
        "name": "2022-11-22",
        "value": "68.34"
    },
    {
        "name": "2022-11-21",
        "value": "65.26"
    },
    {
        "name": "2022-11-19",
        "value": "56.47"
    },
    {
        "name": "2022-11-18",
        "value": "61.74"
    },
    {
        "name": "2022-11-17",
        "value": "61.46"
    },
    {
        "name": "2022-11-16",
        "value": "60.49"
    },
    {
        "name": "2022-11-15",
        "value": "62.31"
    },
    {
        "name": "2022-11-14",
        "value": "64.66"
    },
    {
        "name": "2022-11-12",
        "value": "51.04"
    },
    {
        "name": "2022-11-11",
        "value": "61.53"
    },
    {
        "name": "2022-11-10",
        "value": "64.25"
    },
    {
        "name": "2022-11-09",
        "value": "65.67"
    },
    {
        "name": "2022-11-08",
        "value": "64.60"
    },
    {
        "name": "2022-11-07",
        "value": "65.27"
    },
    {
        "name": "2022-11-05",
        "value": "55.67"
    },
    {
        "name": "2022-11-04",
        "value": "62.11"
    },
    {
        "name": "2022-11-03",
        "value": "60.78"
    },
    {
        "name": "2022-11-02",
        "value": "63.14"
    },
    {
        "name": "2022-11-01",
        "value": "63.53"
    },
    {
        "name": "2022-10-31",
        "value": "67.60"
    },
    {
        "name": "2022-10-28",
        "value": "54.90"
    },
    {
        "name": "2022-10-27",
        "value": "60.52"
    },
    {
        "name": "2022-10-26",
        "value": "56.62"
    },
    {
        "name": "2022-10-25",
        "value": "66.08"
    },
    {
        "name": "2022-10-24",
        "value": "64.12"
    },
    {
        "name": "2022-10-22",
        "value": "51.45"
    },
    {
        "name": "2022-10-21",
        "value": "61.15"
    },
    {
        "name": "2022-10-20",
        "value": "59.63"
    },
    {
        "name": "2022-10-19",
        "value": "59.56"
    },
    {
        "name": "2022-10-18",
        "value": "60.15"
    },
    {
        "name": "2022-10-17",
        "value": "60.66"
    },
    {
        "name": "2022-10-15",
        "value": "57.90"
    },
    {
        "name": "2022-10-14",
        "value": "63.92"
    },
    {
        "name": "2022-10-13",
        "value": "55.53"
    },
    {
        "name": "2022-10-12",
        "value": "57.57"
    },
    {
        "name": "2022-10-11",
        "value": "53.50"
    },
    {
        "name": "2022-10-10",
        "value": "56.85"
    },
    {
        "name": "2022-10-08",
        "value": "48.65"
    },
    {
        "name": "2022-10-07",
        "value": "59.59"
    },
    {
        "name": "2022-10-06",
        "value": "56.83"
    },
    {
        "name": "2022-10-05",
        "value": "59.91"
    },
    {
        "name": "2022-10-04",
        "value": "58.02"
    },
    {
        "name": "2022-10-03",
        "value": "68.38"
    },
    {
        "name": "2022-10-01",
        "value": "53.68"
    },
    {
        "name": "2022-09-30",
        "value": "61.01"
    },
    {
        "name": "2022-09-29",
        "value": "58.84"
    },
    {
        "name": "2022-09-28",
        "value": "58.28"
    },
    {
        "name": "2022-09-27",
        "value": "62.40"
    },
    {
        "name": "2022-09-26",
        "value": "59.28"
    },
    {
        "name": "2022-09-24",
        "value": "63.14"
    },
    {
        "name": "2022-09-23",
        "value": "58.37"
    },
    {
        "name": "2022-09-22",
        "value": "61.37"
    },
    {
        "name": "2022-09-21",
        "value": "61.75"
    },
    {
        "name": "2022-09-20",
        "value": "61.16"
    },
    {
        "name": "2022-09-19",
        "value": "49.50"
    },
    {
        "name": "2022-09-17",
        "value": "51.34"
    },
    {
        "name": "2022-09-16",
        "value": "57.27"
    },
    {
        "name": "2022-09-15",
        "value": "59.31"
    },
    {
        "name": "2022-09-14",
        "value": "57.03"
    },
    {
        "name": "2022-09-13",
        "value": "56.25"
    },
    {
        "name": "2022-09-12",
        "value": "54.83"
    },
    {
        "name": "2022-09-10",
        "value": "47.91"
    },
    {
        "name": "2022-09-09",
        "value": "54.19"
    },
    {
        "name": "2022-09-07",
        "value": "63.40"
    },
    {
        "name": "2022-09-06",
        "value": "58.26"
    },
    {
        "name": "2022-09-05",
        "value": "63.78"
    },
    {
        "name": "2022-09-03",
        "value": "53.07"
    },
    {
        "name": "2022-09-02",
        "value": "56.12"
    },
    {
        "name": "2022-09-01",
        "value": "60.89"
    },
    {
        "name": "2022-08-31",
        "value": "53.05"
    },
    {
        "name": "2022-08-29",
        "value": "53.62"
    },
    {
        "name": "2022-08-27",
        "value": "57.38"
    },
    {
        "name": "2022-08-26",
        "value": "57.08"
    },
    {
        "name": "2022-08-25",
        "value": "61.09"
    },
    {
        "name": "2022-08-24",
        "value": "55.76"
    },
    {
        "name": "2022-08-23",
        "value": "50.08"
    },
    {
        "name": "2022-08-20",
        "value": "46.87"
    },
    {
        "name": "2022-08-19",
        "value": "57.93"
    },
    {
        "name": "2022-08-18",
        "value": "54.52"
    }
]

export const chartData2 = [

        {
            "name": "2023-05-31",
            "value": "50.00"
        },
        {
            "name": "2023-05-30",
            "value": "51.49"
        },
        {
            "name": "2023-05-29",
            "value": "47.25"
        },
        {
            "name": "2023-05-26",
            "value": "49.19"
        },
        {
            "name": "2023-05-25",
            "value": "51.43"
        },
        {
            "name": "2023-05-24",
            "value": "49.73"
        },
        {
            "name": "2023-05-23",
            "value": "42.38"
        },
        {
            "name": "2023-05-22",
            "value": "48.02"
        },
        {
            "name": "2023-05-19",
            "value": "50.18"
        },
        {
            "name": "2023-05-18",
            "value": "50.01"
        },
        {
            "name": "2023-05-17",
            "value": "43.81"
        },
        {
            "name": "2023-05-16",
            "value": "52.33"
        },
        {
            "name": "2023-05-15",
            "value": "45.32"
        },
        {
            "name": "2023-05-12",
            "value": "44.72"
        },
        {
            "name": "2023-05-11",
            "value": "44.51"
        },
        {
            "name": "2023-05-10",
            "value": "42.53"
        },
        {
            "name": "2023-05-05",
            "value": "46.10"
        },
        {
            "name": "2023-05-04",
            "value": "50.28"
        },
        {
            "name": "2023-05-03",
            "value": "40.61"
        },
        {
            "name": "2023-05-02",
            "value": "44.64"
        },
        {
            "name": "2023-04-28",
            "value": "38.01"
        },
        {
            "name": "2023-04-27",
            "value": "49.40"
        },
        {
            "name": "2023-04-26",
            "value": "42.52"
        },
        {
            "name": "2023-04-25",
            "value": "45.04"
        },
        {
            "name": "2023-04-24",
            "value": "45.53"
        },
        {
            "name": "2023-04-21",
            "value": "53.96"
        },
        {
            "name": "2023-04-20",
            "value": "46.62"
        },
        {
            "name": "2023-04-19",
            "value": "46.64"
        },
        {
            "name": "2023-04-18",
            "value": "43.56"
        },
        {
            "name": "2023-04-17",
            "value": "38.28"
        },
        {
            "name": "2023-02-21",
            "value": "54.50"
        },
        {
            "name": "2023-02-20",
            "value": "66.91"
        },
        {
            "name": "2023-02-17",
            "value": "46.86"
        },
        {
            "name": "2023-02-16",
            "value": "57.89"
        },
        {
            "name": "2023-02-15",
            "value": "43.85"
        },
        {
            "name": "2023-02-14",
            "value": "57.68"
        },
        {
            "name": "2023-02-10",
            "value": "42.62"
        },
        {
            "name": "2023-02-08",
            "value": "49.57"
        },
        {
            "name": "2023-01-26",
            "value": "58.80"
        }

]

export const chartData3 = [
    {
        "name": "2023-07-22",
        "value": "46.68"
    },
    {
        "name": "2023-07-21",
        "value": "53.17"
    },
    {
        "name": "2023-07-20",
        "value": "50.28"
    },
    {
        "name": "2023-07-19",
        "value": "51.19"
    },
    {
        "name": "2023-07-18",
        "value": "49.79"
    },
    {
        "name": "2023-07-17",
        "value": "45.24"
    },
    {
        "name": "2023-07-15",
        "value": "45.91"
    },
    {
        "name": "2023-07-14",
        "value": "47.00"
    },
    {
        "name": "2023-07-13",
        "value": "47.92"
    },
    {
        "name": "2023-07-12",
        "value": "49.09"
    },
    {
        "name": "2023-07-11",
        "value": "54.20"
    },
    {
        "name": "2023-07-10",
        "value": "54.49"
    },
    {
        "name": "2023-07-08",
        "value": "52.12"
    },
    {
        "name": "2023-07-07",
        "value": "50.51"
    },
    {
        "name": "2023-07-06",
        "value": "48.98"
    },
    {
        "name": "2023-07-05",
        "value": "50.67"
    },
    {
        "name": "2023-07-04",
        "value": "52.93"
    },
    {
        "name": "2023-07-03",
        "value": "48.81"
    },
    {
        "name": "2023-07-01",
        "value": "51.98"
    },
    {
        "name": "2023-06-30",
        "value": "54.01"
    },
    {
        "name": "2023-06-29",
        "value": "54.30"
    },
    {
        "name": "2023-06-26",
        "value": "51.97"
    },
    {
        "name": "2023-06-24",
        "value": "52.17"
    },
    {
        "name": "2023-06-23",
        "value": "52.56"
    },
    {
        "name": "2023-06-22",
        "value": "53.88"
    },
    {
        "name": "2023-06-21",
        "value": "52.40"
    },
    {
        "name": "2023-06-20",
        "value": "56.43"
    },
    {
        "name": "2023-06-19",
        "value": "50.93"
    },
    {
        "name": "2023-06-17",
        "value": "53.13"
    },
    {
        "name": "2023-06-16",
        "value": "54.37"
    },
    {
        "name": "2023-06-15",
        "value": "53.65"
    },
    {
        "name": "2023-06-14",
        "value": "54.22"
    },
    {
        "name": "2023-06-13",
        "value": "51.46"
    },
    {
        "name": "2023-06-12",
        "value": "49.01"
    },
    {
        "name": "2023-06-10",
        "value": "53.04"
    },
    {
        "name": "2023-06-09",
        "value": "51.46"
    },
    {
        "name": "2023-06-08",
        "value": "52.95"
    },
    {
        "name": "2023-06-07",
        "value": "50.30"
    },
    {
        "name": "2023-06-06",
        "value": "51.13"
    },
    {
        "name": "2023-06-05",
        "value": "52.49"
    },
    {
        "name": "2023-06-03",
        "value": "50.47"
    },
    {
        "name": "2023-06-02",
        "value": "53.07"
    },
    {
        "name": "2023-06-01",
        "value": "51.12"
    },
    {
        "name": "2023-05-31",
        "value": "50.45"
    },
    {
        "name": "2023-05-30",
        "value": "51.47"
    }
]
