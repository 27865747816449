import React from 'react';
import './Footer.scss'
import LogoIcon from "../../assets/icons/LogoIcon";

const Footer = (props: {animation: boolean}) => {
    return (
        <div id='footer' className='footer'>
            <div className='footer-logo'><LogoIcon/></div>
            {/* <div className='footer-contacts'> */}
                {/* <a href="mailto:sales@solut.ai">sales@solut.ai</a> */}
            {/* </div> */}
        </div>
    );
};

export default Footer;
