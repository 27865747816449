import React, {useContext} from 'react';
import './ContactCard.scss'
import PhoneIcon from "../../../assets/social-icons/PhoneIcon";
import MailIcon from "../../../assets/social-icons/MailIcon";
import WhatsApp from "../../../assets/social-icons/WhatsApp";
import {TranslateContext} from "../../../App";


type Manager = {
    name: string; country: string; lang: string;
    avatar: string; contact: { phone: string; mail: string; contactNumber: string }
}

const ContactCard = (manager: Manager) => {

    const translate = useContext(TranslateContext);


    return (
        <div className='contact-card'>
            <div className='card-header'>
                {manager.avatar && <img className='avatar' src={manager.avatar} alt="avatar"/> }
                <div className='card-header-info'>
                    <div className='manager-name'>{manager.name}</div>
                    <p>{manager.country}</p>
                    <p>{manager.lang}</p>
                </div>
            </div>
            <div className='card-contacts'>
                <div>
                    <PhoneIcon/><p>{manager.contact.phone}</p>
                </div>
                <div>
                    <MailIcon/><p>{manager.contact.mail}</p>
                </div>
                <div>
                    <a href={`https://wa.me/${manager.contact.contactNumber}`} style={{background: 'none', width: '25px'}}><img src='/images/whatsUp.png' style={{ width: '25px', borderRadius: '0px'}} /></a>
                    {/*<WhatsApp/>*/}
                    <a href={`https://wa.me/${manager.contact.contactNumber}`}>{translate.contacts.card1.button}</a>
                </div>
            </div>
        </div>
    );
};

export default ContactCard;
