import React, {useContext, useLayoutEffect, useRef, useState} from 'react';
import './Map.scss'
import MapImg from "../../assets/img/MapImg";
import Heading from "../atoms/Heading/Heading";
import {TranslateContext} from "../../App";
import MapImgMobile from '../../assets/img/MapImgMobile';

const Map = React.memo((props: { animation: boolean }) => {
    const translate = useContext(TranslateContext);

    const [showCard, setShow] = useState(false)

    const cityDots = [
        {
            x: 845,
            y: 175,
            img: '/images/lahta.png',
            description: 'Самый высокий небоскреб Европы'
        },
        {
            x: 850,
            y: 310
        },
        {
            x: 805,
            y: 280
        },
        {
            x: 950,
            y: 390
        },

    ]


    const heading = useRef<HTMLDivElement>(null);
    const dots = useRef<HTMLDivElement>(null);

    let observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (heading.current && dots.current) {
                if (entry.intersectionRatio > 0.2) {
                    heading.current.style.transform = 'translate3d(0,0,0)'
                    dots.current.style.opacity = '1'
                }

                if (entry.intersectionRatio < 0.8) {
                    heading.current.style.transform = 'translate3d(100vw,0,0)'
                    dots.current.style.opacity = '0'
                }
            }
        });
    });
    const width = window.innerWidth > 980

    useLayoutEffect(() => {
        if (props.animation) {
            let elShoe = document.querySelector('.map');
            // @ts-ignore
            observer.observe(elShoe);
        }
        document.querySelector('#circle1')!.addEventListener('mouseover', () => hoverDot('1'));
        document.querySelector('#circle2')!.addEventListener('mouseover', () => hoverDot('2'));
        document.querySelector('#circle3')!.addEventListener('mouseover', () => hoverDot('3'));
        document.querySelector('#circle4')!.addEventListener('mouseover', () => hoverDot('4'));
        document.querySelector('#circle5')!.addEventListener('mouseover', () => hoverDot('5'));

        document.querySelector('#circle1')!.addEventListener('mouseover', (el) => document.querySelector(`#circle1`)!.classList.add('dot-hover'));
        document.querySelector('#circle2')!.addEventListener('mouseover', (el) => document.querySelector(`#circle2`)!.classList.add('dot-hover'));
        document.querySelector('#circle3')!.addEventListener('mouseover', (el) => document.querySelector(`#circle3`)!.classList.add('dot-hover'));
        document.querySelector('#circle4')!.addEventListener('mouseover', (el) => document.querySelector(`#circle4`)!.classList.add('dot-hover'));
        document.querySelector('#circle5')!.addEventListener('mouseover', (el) => document.querySelector(`#circle5`)!.classList.add('dot-hover'));


        document.querySelector('#circle1')!.addEventListener('mouseout', () => outDot('1'));
        document.querySelector('#circle2')!.addEventListener('mouseout', () => outDot('2'));
        document.querySelector('#circle3')!.addEventListener('mouseout', () => outDot('3'));
        document.querySelector('#circle4')!.addEventListener('mouseout', () => outDot('4'));
        document.querySelector('#circle5')!.addEventListener('mouseout', () => outDot('5'));

    }, [])

    const hoverDot = (el:string) => {
        console.log('tut')

        document.querySelector(`#circle${el}`)!.classList.add('dot-hover')
        // document.querySelector(`.card-map${el}`)!.classList.add('show')
        document.querySelector(`.card-map-img${el}`)!.classList.add('show')
        document.querySelector(`.card-map-text${el}`)!.classList.add('show')

        setShow(true)
    }

    const outDot = (el:string) => {
        document.querySelector(`#circle${el}`)!.classList.remove('dot-hover')
        // document.querySelector(`.card-map${el}`)!.classList.remove('show')
        document.querySelector(`.card-map-img${el}`)!.classList.remove('show')
        document.querySelector(`.card-map-text${el}`)!.classList.remove('show')

        setShow(false)
    }

    return (
        <div className='map'>
            <div className={props.animation ? 'map-heading' : 'map-heading non-animation'} ref={heading}>
                <Heading text={translate.map.heading} position={'end'}/>
            </div>
            {/*{window.innerWidth > 980 ?*/}
            {/*<div className={props.animation ? 'dots-wrapper' : 'dots-wrapper non-animation'}  ref={dots}>*/}
            {/*    {cityDots.map((el) =>*/}
            {/*        <div key={Math.random()}*/}
            {/*             className={el.description ? 'dot dot-card' : 'dot'}*/}
            {/*             onMouseEnter={el.description ? () => setShow(true) : ()=>{}}*/}
            {/*             onMouseOut={el.description ? () => setShow(false) : ()=>{}}*/}
            {/*             style={{left: '614', top:' 324'}}>*/}
            {/*            {showCard ?*/}
            {/*                <div id='dot-card' className={el.description ? 'card cardShow' : ''}><img src={el.img}/><div>{el.description}</div>*/}
            {/*                </div> : null}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div> : null}*/}
            <div className='map-img'>
                {width ?  <MapImg/> :  <MapImgMobile/> }

            </div>
        </div>
    );
});

export default Map;
